/* .img-box section {
    padding: 2rem 0;
  }
  
.img-box label {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dotted black;
    border-radius: 20px;
    width: 10rem;
    height: 10rem;
    cursor: pointer;
    font-size: large;
  }
  
.img-box label span {
    font-weight: lighter;
    font-size: small;
    padding-top: 0.5rem;
  }
  .img-box  input {
    display: none;
  }
  
.img-box img {
    padding: 0;
    margin: 0;
  }
  
.img-box  .images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
.img-box  .image {
    margin: 1rem 0.5rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }
  
 .img-box .image button {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border: none;
    color: white;
    background-color: lightcoral;
  }
  
 .img-box .image button:hover {
    background-color: red;
  }
  
.img-box  .image p {
    padding: 0 0.5rem;
    margin: 0;
  }
  
 .img-box .upload-btn {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    border: none;
    border-radius: 20px;
    width: 10rem;
    height: 3rem;
    color: white;
    background-color: green;
  }
  
 .img-box label:hover,
  .upload-btn:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
  
 .img-box .error {
    text-align: center;
  }
  
 .img-box .error span {
    color: red;
  } */
  .image-item img{
      width:100%;
  }